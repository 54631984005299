import { AppConfig } from '../../common/models/AppConfig';
import { POSType } from '../../common/models/POSProfile';

export const config: AppConfig = {
    apiUrl: 'https://dev-bff.cxicodes.com/graphql',
    googleMapsApiKey: 'AIzaSyDwzWdJbwhx317wBL6qxG2_X1tfm1huNd0',
    appInsightsInstrumentationKey: '73abcc5c-009e-4815-9b69-bc681b6ed786',
    msal: {
        clientId: '6ba2fb0a-1235-4b37-a899-bed347537f03',
        authority: 'https://cxicode.b2clogin.com/cxicode.onmicrosoft.com/B2C_1A_dynamic_susi_dev',
        knownAuthorities: ['cxicode.b2clogin.com'],
        apiScopes: {
            read: 'https://cxicode.onmicrosoft.com/067c51be-0735-413d-9ac2-836b3fa3b7e8/read',
            write: 'https://cxicode.onmicrosoft.com/067c51be-0735-413d-9ac2-836b3fa3b7e8/write',
        },
    },
    chargify: {
        publicKey: 'chjs_vpmky4fjxj4rfvw4j3cnrdht',
        serverHost: 'https://customerxi-sandbox.chargify.com',
    },
    enabledPOS: [POSType.Toast, POSType.ParBrink, POSType.Square, POSType.Omnivore,POSType.Olo],
};
