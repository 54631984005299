import { gql } from '@apollo/client';
import { PartnerDataAvailability } from '../../../common/models/PartnerDataAvailability';

export interface GetPartnerDataAvailabilityVars {
    partnerId?: string;
}

export interface GetPartnerDataAvailabilityData {
    partnerDataAvailablity: PartnerDataAvailability;
}

export const GET_PARTNER_DATA_AVAILABILITY = gql`
    query GetPartnerDataAvailablity($partnerId: String) {
        partnerDataAvailablity(input: { partnerId: $partnerId }) {
            tMIDataAvailable
            cTIDataAvailable
        }
    }
`;
