import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import {
    InteractionStatus,
    InteractionType,
    RedirectRequest,
} from '@azure/msal-browser';
import { appConfigVar } from '../../graphql/reactive-vars/app-config';
import { ComponentProps } from '../../common/types';

export default function MsalAuthWrapper({ children }: ComponentProps) {
    const authRequest: RedirectRequest = {
        authority: appConfigVar().msal.authority,
        scopes: [],
    };

    const { inProgress } = useMsal();

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={({ login }) => login()}
        >
            {inProgress === InteractionStatus.None && <>{children}</>}
        </MsalAuthenticationTemplate>
    );
}
