import { gql } from '@apollo/client';
import { UserProfile } from '../../../common/models/UserProfile';
import { USER_PROFILE_FIELDS } from './userProfilePayload';

export interface GetUserProfileData {
    userProfile: UserProfile;
}

export interface GetUserProfileVars {
    email: string;
}

export const GET_USER_PROFILE = gql`
    ${USER_PROFILE_FIELDS}
    query GetUserProfile($email: String!) {
        userProfile(email: $email) {
            ...UserProfileFields
        }
    }
`;
