import { gql } from '@apollo/client';

export const PARTNER_PROFILE_FIELDS = gql`
    fragment PartnerProfileFields on PartnerProfilePayload {
        partnerId
        partnerName
        address
        partnerType
        userProfileEmails
        amountOfLocations
        serviceAgreementAccepted
        isOnBoarded
        subscription {
            subscriptionId
            lastBilledDate
            state
        }
    }
`;
