import { ComponentProps } from '../../common/types';
import { Overlay, Loader } from './styles';

export function PageLoader({ className }: ComponentProps) {
    return (
        <Overlay className={className}>
            <Loader />
        </Overlay>
    );
}
