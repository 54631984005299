import { ApolloError, ServerError } from '@apollo/client';

export function getErrorStatusCode(error: ApolloError): number | undefined {
    const serverErrorStatusCode = (
        error.networkError as ServerError | undefined
    )?.statusCode;

    const extensionErrorStatusCode =
        error.graphQLErrors[0]?.extensions?.ServiceResponseCode;

    return serverErrorStatusCode || extensionErrorStatusCode;
}
