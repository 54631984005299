import { colors } from './colors';

export const chartsPalette = [
    colors.azureRadiance,
    colors.wildWatermelon,
    colors.caribbeanGreenSub1,
    colors.mustard,
    colors.heliotrope,
    colors.electricViolet,
    colors.vividTangerine,
    colors.funBlue,
    colors.persianPink,
    colors.cerulean,
];

export const layout = {
    headerHeight: '60px',
    navbarWidth: '230px',
};
export const specialEventColorPalette = [
    colors.caribbeanGreenSub1,
    colors.persianPink,
    colors.vividTangerine,
    colors.funBlue,
    colors.lemon,
    colors.mediumSeaGreen,
    colors.coral,
];

export const CampaignPalette = [
    colors.coral,
    colors.mediumSeaGreen,
    colors.vividTangerine,
    colors.persianPink,
    colors.funBlue,
    colors.vividTangerine,
    colors.lemon,
];
