import { gql } from '@apollo/client';
import { UserProfile } from '../../../common/models/UserProfile';
import { USER_PROFILE_FIELDS } from './userProfilePayload';

export interface UpdateUserProfileData {
    updateUserProfile: UserProfile;
}

export interface UpdateUserProfileVars {
    input: {
        email: string;
        invitationAccepted: boolean;
    };
}

export const UPDATE_USER_PROFILE = gql`
    ${USER_PROFILE_FIELDS}
    mutation UpdateUserProfile($input: UserProfileUpdateInput!) {
        updateUserProfile(input: $input) {
            ...UserProfileFields
        }
    }
`;
