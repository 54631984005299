export enum UserRole {
    Owner = 'OWNER',
    Associate = 'ASSOCIATE',
    SuperUser = 'SUPER_USER',
}

export interface UserProfile {
    email: string;
    partnerId: string;
    role: UserRole;
    invitationAccepted: boolean;
    lastactiveDate? : string | Date;
}
export interface PartnerThemingItems {
    primaryColors : string;
    secondaryColors : string;
    highlights : string;
    textColors : string;
    colorBinaryCode : string;
    logoBinaryCode : string;
    bannerBinaryCode : string;
    isPartnerThemeConfigured : boolean;
}