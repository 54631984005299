import { gql } from '@apollo/client';

export const USER_PROFILE_FIELDS = gql`
    fragment UserProfileFields on UserProfilePayload {
        email
        partnerId
        role
        invitationAccepted
    }
`;
