import { MsalProvider as BaseMsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { appConfigVar } from '../../graphql/reactive-vars/app-config';
import { ComponentProps } from '../../common/types';

export default function MsalProvider({ children }: ComponentProps) {
    const { msal } = appConfigVar();

    const client = new PublicClientApplication({
        auth: {
            clientId: msal.clientId,
            authority: msal.authority,
            knownAuthorities: msal.knownAuthorities,
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false,
        },
    });

    return <BaseMsalProvider instance={client}>{children}</BaseMsalProvider>;
}
