import { GraphqlEntity } from '../types';

export enum POSType {
    Square = 'square',
    Omnivore = 'omnivore',
    NCR = 'ncr',
    ParBrink = 'parbrink',
    Toast = 'toast',
    Breadcrumb = 'breadcrumb',
    Clover = 'clover',
    Revel = 'revel',
    Shopify = 'shopify',
    Posnow = 'posnow',
    Heartland = 'heartland',
    TouchBistro = 'touchBistro',
    Micros = 'micros',
    SpotOn = 'spoton',
    LightSpeed ='lightspeed',
    Shift4 ='shift4',
    Thanx = 'thanx',
    Punchh ='punchh',
    Paytronix = 'paytronix',
    Olo = 'olo'

}

export interface POSProfile extends GraphqlEntity<'PosProfilePayload'> {
    posCredentialsConfigurations: POSCredentialsConfiguration[];
}

export interface POSCredentialsConfiguration
    extends GraphqlEntity<'PosCredentialsConfiguration'> {
    posType: POSType;
}
