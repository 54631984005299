import { gql } from '@apollo/client';
import { PartnerThemingItems,PartnerConfig } from '../../../common/models/PartnerTheming';

export interface GetPartnerConfigData {
    partnerConfig: PartnerConfig;
}

export interface GetPartnerConfigVars {
    input: {
        partnerId?: string;
    }
}

export const GET_PARTNER_CONFIG = gql`
query PartnerConfig($input: PartnerSpecificInput!) {
    partnerConfig(input: $input){
        partnerConfig
        {
        partnerId
        identityPhoneFlag
        identityEmailFlag  
        identityIOSFlag
        identityAndroidFlag
        }
    }
}
`;
