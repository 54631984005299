import { RoundRadius } from '../models/theme';

export const roundRadius: RoundRadius = {
    radius1: '4px',
    radius2: '8px',
    radius3: '20px',
    radius4: '50%',
    radius5: '3px',
    radius6: '16px',
    radius7: '100%',
};
