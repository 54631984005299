import { gql } from '@apollo/client';
import { PartnerProfile } from '../../../common/models/PartnerProfile';
import { PARTNER_PROFILE_FIELDS } from './partnerProfilePayload';

export interface GetPartnerProfileData {
    partnerProfile: PartnerProfile;
}

export interface GetPartnerProfileVars {}

export const GET_PARTNER_PROFILE = gql`
    ${PARTNER_PROFILE_FIELDS}
    query GetPartnerProfile {
        partnerProfile {
            ...PartnerProfileFields
        }
    }
`;
