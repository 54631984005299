import { gql } from '@apollo/client';
import { PartnerThemingItems } from '../../../common/models/PartnerTheming';

export interface GetPartnerThemeData {
    allPartnerThemes: PartnerThemingItems;
}

export interface GetPartnerThemeVars {
   partnerId?: string;
}

export const GET_ALL_PARTNER_THEMES = gql`
query GetAllPartnerThemes($partnerId: String!) {
    allPartnerThemes(partnerId: $partnerId){
        primaryColors
        secondaryColors
        highlights
        textColors
        colorBinaryCode
        logoBinaryCode
        bannerBinaryCode
        isPartnerThemeConfigured
    }
}
`;
