import { useEffect, useState } from 'react';
import { useQuery, useMutation, ServerError, ApolloError, useReactiveVar } from '@apollo/client';
import { useAuth } from '../../common/hooks/useMsalAuth';
import { userVar } from '../../graphql/reactive-vars/user';
import { PageLoader } from '../../components/page-loader';
import {
    GetUserProfileData,
    GetUserProfileVars,
    GET_USER_PROFILE,
} from '../../graphql/operations/user-profile/getUserProfile';
import {
    GetPartnerDataAvailabilityData,
    GetPartnerDataAvailabilityVars,
    GET_PARTNER_DATA_AVAILABILITY,
} from '../../graphql/operations/partner-data-availability/getPartnerDataAvailability';
import {
    UPDATE_USER_PROFILE,
    UpdateUserProfileData,
    UpdateUserProfileVars,
} from '../../graphql/operations/user-profile/updateUserProfile';
import {
    GET_PARTNER_PROFILE,
    GetPartnerProfileData,
} from '../../graphql/operations/partner-profile/getPartnerProfile';
import {
    GET_ALL_PARTNER_THEMES,
    GetPartnerThemeData,
    GetPartnerThemeVars
} from '../../graphql/operations/partner-theming/getAllPartnerThemes';
import {
    GET_PARTNER_CONFIG,
    GetPartnerConfigData,
    GetPartnerConfigVars
} from '../../graphql/operations/partner-profile/getPartnerConfig';
import { getAppConfig } from '../config/getAppConfig';
import { appConfigVar } from '../../graphql/reactive-vars/app-config';
import { ComponentProps } from '../../common/types';
import { AppConfig } from '../../common/models/AppConfig';
import { UserRole } from '../../common/models/UserProfile';
import { getErrorStatusCode } from '../../graphql/utilities/error';
import { appStatusVar } from '../../graphql/reactive-vars/app-status';
import { SubscriptionState } from '../../common/models/PartnerProfile';

export function ConfigBootstrapContainer({ children }: ComponentProps) {
    let [appConfig, setAppConfig] = useState<AppConfig | null>(null);

    useEffect(() => {
        getAppConfig()
            .then((cfg) => {
                appConfigVar(cfg);
                setAppConfig(cfg);
            })
            .catch(console.error);
    }, []);

    return appConfig ? <>{children}</> : <PageLoader />;
}

export function UserDataBootstrapContainer({ children }: ComponentProps) {
    const [initialized, setInitialized] = useState(false);
    const { idTokenClaims } = useAuth();
    const { email, given_name, family_name } = idTokenClaims;
    const user = useReactiveVar(userVar);
    const [gmapsLoading, setGmapsLoading] = useState(true);

    const {
        loading: userProfileLoading,
        data: userProfileData,
        error,
    } = useQuery<GetUserProfileData, GetUserProfileVars>(GET_USER_PROFILE, {
        variables: {
            email,
        },
        onError: createCriticalErrorHandler(true),
    });

    const partnerId = userProfileData?.userProfile.partnerId;

    const userNotFound =
        !!error?.graphQLErrors.some(
            (err: Record<string, any>) => err.extensions.ServiceResponseCode === 404
        ) ||
        (error?.networkError as ServerError)?.result?.errors.some(
            (err: Record<string, any>) => err.extensions.ServiceResponseCode === 404
        );

    useEffect(() => {
        if (!userProfileLoading && !userNotFound) {
            return;
        }
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${
            appConfigVar().googleMapsApiKey
        }&libraries=places&language=en`;
        script.async = true;
        document.body.appendChild(script);
        script.addEventListener('load', () => {
            setGmapsLoading(false);
        });
        
    }, [userNotFound, userProfileLoading]);

    const { loading: partnerProfileLoading, data: partnerProfileData } =
        useQuery<GetPartnerProfileData>(GET_PARTNER_PROFILE, {
            skip: !partnerId || userProfileLoading,
            onError: createCriticalErrorHandler(),
        });
       
    const isOnboardingCompleted = partnerProfileData?.partnerProfile.isOnBoarded;
    const partnerName = partnerProfileData?.partnerProfile.partnerName;

    const { loading: partnerDataAvailabilityLoading, data: partnerDataAvailability } = useQuery<
        GetPartnerDataAvailabilityData,
        GetPartnerDataAvailabilityVars
    >(GET_PARTNER_DATA_AVAILABILITY, {
        variables: {
            partnerId: user.selectedPartnerId === undefined ? partnerId : user.selectedPartnerId,
        },
        skip: !partnerId || userNotFound || !isOnboardingCompleted,
        onError: createCriticalErrorHandler(),
    });

    const [updateUserProfile] = useMutation<UpdateUserProfileData, UpdateUserProfileVars>(
        UPDATE_USER_PROFILE,
        {
            variables: {
                input: {
                    email,
                    invitationAccepted: true,
                },
            },
        }
    );

    useEffect(() => {
        if (
            userProfileData?.userProfile.role === UserRole.Associate &&
            !userProfileData?.userProfile.invitationAccepted
        ) {
            updateUserProfile();
        }
    }, [userProfileData]);
    const {
        loading: partnerThemingLoading,
        data: partnerThemingData,
    } = useQuery<GetPartnerThemeData, GetPartnerThemeVars>(GET_ALL_PARTNER_THEMES, {
        variables: {
            partnerId
        },
        skip: !partnerId,
    });
    const {
        loading: partnerConfigLoading,
        data: partnerConfigData,
    } = useQuery<GetPartnerConfigData, GetPartnerConfigVars>(GET_PARTNER_CONFIG, {
        variables: {
            
                input :{
                    partnerId: partnerId
                }
            
        },
        skip: !partnerId,
    });
    useEffect(() => {
        if (
            userProfileLoading ||
            partnerProfileLoading ||
            partnerDataAvailabilityLoading ||
            gmapsLoading || partnerThemingLoading || partnerConfigLoading
        ) {
            return;
        }
       
        userVar({
            ...user,
            email,
            firstName: given_name,
            lastName: family_name,
            role: userProfileData?.userProfile.role || UserRole.Associate,
            isSubscriptionActive : true,
            // isSubscriptionActive:
            //     partnerProfileData?.partnerProfile.subscription.state === SubscriptionState.ACTIVE,
            serviceAndDataAgreementAccepted:
                !!partnerProfileData?.partnerProfile.serviceAgreementAccepted,
            partnerId: partnerId as string,
            isNewUser: userNotFound,
            isOnboardingCompleted: !!isOnboardingCompleted,
            identityPhoneFlag: !!partnerConfigData?.partnerConfig.partnerConfig[0].identityPhoneFlag,
            identityEmailFlag: !!partnerConfigData?.partnerConfig.partnerConfig[0].identityEmailFlag,
            identityAndroidFlag : !!partnerConfigData?.partnerConfig.partnerConfig[0].identityAndroidFlag,
            identityIOSFlag : !!partnerConfigData?.partnerConfig.partnerConfig[0].identityIOSFlag,
            tMIDataAvailable: !!partnerDataAvailability?.partnerDataAvailablity.tMIDataAvailable,
            cTIDataAvailable: !!partnerDataAvailability?.partnerDataAvailablity.cTIDataAvailable,
            partnerName: partnerName as string,
            partnerTheming: partnerThemingData?.allPartnerThemes
        });
        setInitialized(true);
    }, [
        userProfileLoading,
        partnerProfileLoading,
        partnerDataAvailabilityLoading,
        gmapsLoading,
        partnerDataAvailability,
        partnerThemingLoading,
        partnerConfigLoading
    ]);
    // useEffect(() => {
    //     if(user.partnerId == null || user.partnerId == undefined ||  user.partnerId == '')
    //     return
    //    runAuditLog("Login","Login")
    // }, [user.partnerId]);
  
    return !initialized ? <PageLoader /> : <>{children}</>;
}

function createCriticalErrorHandler(ignoreNotFound = false) {
    return (error: ApolloError) => {
        const statusCode = getErrorStatusCode(error);
        if (ignoreNotFound && statusCode === 404) {
            return;
        }

        appStatusVar({
            hasCriticalError: true,
        });
    };
}
