import React, { Suspense } from 'react';
import MsalAuthWrapper from './auth/MsalAuthWrapper';
import { ConfigBootstrapContainer } from './bootstrap/BootstrapContainer';
import { PageLoader } from '../components/page-loader';
import MsalProvider from './auth/MsalProvider';
import DemographicDistribution from '../pages/demographic-distribution/DemographicDistribution';
import { ThemeProvider } from './theme/ThemeProvider';
import { FullPicker } from '../components/fullPicker';

const Root = React.lazy(() => import('./root/Root'));

export default function App() {
    return (
        <ConfigBootstrapContainer>
            <MsalProvider>
                <MsalAuthWrapper>
                    <Suspense fallback={<PageLoader />}>
                        <Root />
                    </Suspense>
                </MsalAuthWrapper>
            </MsalProvider>
        </ConfigBootstrapContainer>
    );
}
