import { useMsal } from '@azure/msal-react';
import { IdTokenClaims } from '../models/IdTokenClaims';

export interface Auth {
    idTokenClaims: IdTokenClaims;
    logout: () => Promise<void>;
}

export const useAuth = (): Auth => {
    const { accounts, instance } = useMsal();
    const account = accounts[0];

    return {
        idTokenClaims: account.idTokenClaims as IdTokenClaims,
        logout: () =>
            instance.logoutRedirect({
                account,
                postLogoutRedirectUri: window.location.origin,
            }),
    };
};
