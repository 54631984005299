import styled, { keyframes } from 'styled-components';
import { colors } from '../../common/styles';

const loadAnimation = keyframes`
    0% {
         height: 4em; 
         box-shadow: 0 0; 
    }
    80% {
        height: 4em; 
        box-shadow: 0 0;
    }
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
`;

export const Overlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
`;

export const Loader = styled.div`
    color: ${colors.blue};
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    transform: translateZ(0);
    animation-delay: -0.16s;

    &,
    &::before,
    &::after {
        background: ${colors.blue};
        animation-name: ${loadAnimation};
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        width: 1em;
        height: 4em;
    }

    &::before,
    &::after {
        position: absolute;
        top: 0;
        content: '';
    }

    &::before {
        left: -1.5em;
        animation-delay: -0.32s;
    }

    &::after {
        left: 1.5em;
    }
`;
