import { makeVar } from '@apollo/client';
import { UserRole,PartnerThemingItems } from '../../common/models/UserProfile';

export interface UserVar {
    partnerId: string;
    role: UserRole;
    email: string;
    firstName: string;
    lastName: string;
    isNewUser: boolean;
    isOnboardingCompleted: boolean;
    isSubscriptionActive: boolean;
    serviceAndDataAgreementAccepted: boolean;
    tMIDataAvailable: boolean;
    cTIDataAvailable: boolean;
    partnerName: string;
    selectedPartnerId?: string;
    selectedPartnerName?: string;
    partnerTheming?:PartnerThemingItems;
    resetEnabled? : boolean;
    identityPhoneFlag : boolean;
    identityEmailFlag: boolean;  
    identityIOSFlag: boolean;
    identityAndroidFlag: boolean;
}

export const userVar = makeVar<UserVar>({
    partnerId: '',
    role: UserRole.Associate,
    email: '',
    firstName: '',
    lastName: '',
    isNewUser: true,
    isOnboardingCompleted: false,
    isSubscriptionActive: true,
    serviceAndDataAgreementAccepted: false,
    tMIDataAvailable: false,
    cTIDataAvailable: false,
    partnerName: '',
    selectedPartnerId: undefined,
    selectedPartnerName: undefined,
    partnerTheming : undefined,
    resetEnabled : false,
    identityPhoneFlag : false,
    identityEmailFlag: false,
    identityIOSFlag: false,
    identityAndroidFlag: false
});
