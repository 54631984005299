import { AppConfig } from '../../common/models/AppConfig';
import { config as devConfig } from './config.dev';

const CONFIG_FETCH_URL = '/config.json';

export async function getAppConfig(): Promise<AppConfig> {
    const response = await fetch(CONFIG_FETCH_URL);

    try {
        const config = await response.json();
        return config;
    } catch {
        console.warn(
            'Could not fetch remote config, fallback to development config'
        );
        return devConfig;
    }
}
